/* eslint-disable max-len */
import { ApiPaginatedResponse } from '../commonApiTypes'


export type TPlaceService = {
  id: number
  description: string
  have_facility: boolean
  service: number
  service_category: number
  service_category_name: string
  service_name: string
}

export type TPlaceCategory = {
  cert_image: null | string
  decree_date: null | string
  decree_number: string
  expiration: null | string
  number: string
  stars_count: number
  status: string
}

export type TPlacePolicy = {
  checkin_time: string
  checkout_time: string
  minimal_children_age: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  pets: 0 | 1
  smoking: 0 | 1 | 2
  quiet_time: 0 | 1
  quiet_time_end: string
  quiet_time_start: string
}

export const GET_PLACE_SERVICES = 'v1/account/objects/:id/services/'
export type GetPlaceServicesResp = ApiPaginatedResponse<TPlaceService[]>

export const GET_PLACE_DESCRIPTION_URL = 'v1/account/objects/:id/'
export type GetPlaceDesciptionResp = { description: string | null }

export const GET_POLICY_URL = 'v1/account/objects/:id/policy/'
export type GetPolicyResp = TPlacePolicy

export const GET_PLACE_CATEGORY = 'v1/account/hotels/:id/hotel_category/'
export type GetPlaceCategoryResp = any
