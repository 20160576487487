/* eslint-disable max-len */

export type SendPaymentMethodRes = {
  url: string
  params: {
    cps_email: string
    cps_phone: string
    customerNumber: string
    orderNumber: string
    paymentType: string
    scid: number
    shopFailURL: string
    shopId: number
    shopSuccessURL: string
    sum: number
    ym_merchant_receipt: string
  }
}
  
  
export type SendPaymentMethodReq = { payment_method: 'ac' | 'sb' | 'gp' | 'ab' | 'pc' }
    
export const SEND_PAYMENT_METHOD = '/v1/account/payments/:paymentId/pay/'
