export const URL = '/v1/countries/'

export type Country = {
  id: number
  name: string
  title: string
}

export type Req = void

export type Resp = {
  results: Country[]
}
