// роуты обьекта
export const ACCOMS_LIST_URL = '/objects'

// редактирование обьекта
export const ACCOMS_EDIT_URL = '/objects/:id/*'
export const ACCOMS_EDIT_PHONES_URL = '/objects/:id/phones'
export const ACCOMS_EDIT_NAME_ADDRESS_URL = '/objects/:id/name'
export const ACCOMS_EDIT_SERVICES_URL = '/objects/:id/services'
export const ACCOMS_EDIT_MEDIA_URL = '/objects/:id/media'
export const ACCOM_EDIT_COMMON_ALBUB_URL = '/objects/:id/media/album'
export const ACCOMS_EDIT_MEDIA_VIDEO_URL = '/objects/:id/media/video'
export const ACCOMS_EDIT_ACCOMODATIONS_URL = '/objects/:id/accomodations'
export const ACCOMS_EDIT_CATEGORY_ACCOMODATION_URL = '/objects/:id/accomodations/:categoryId'
export const ACCOMS_EDIT_ACCOMODATIONS_ROOMS_URL = '/objects/:id/accomodations/rooms'
export const ACCOMS_EDIT_PRICES_URL = '/objects/:id/prices'
export const ACCOMS_EDIT_PRICES_YEAR_URL = '/objects/:id/prices/:tab'
export const ACCOM_PORTAL_URL = '/:city/hotels/:id/'
export const ACCOM_ALBUM_ROOM = '/objects/:id/media/album/:roomTypeId'
export const ACCOM_COMMON_ALBUM_ROOM = '/objects/:id/media/album/common'


// добавление обьекта
export const ADD_ACCOM_TYPE_URL = '/objects/add/type'
export const ADD_ACCOM_REGION_URL = '/objects/add/region'
export const ADD_ACCOM_ADDRESS_URL = '/objects/add/address'

// роуты календаря
export const CALENDAR_URL = '/calendar'
export const ACCOM_CALENDAR_URL = '/calendar/:id'
export const ACCOM_CALENDAR_WITH_DATE_URL = '/calendar/:id/:date'

// роуты отзывов
export const REVIEWS_URL = '/reviews'
export const ACCOM_REVIEWS_URL = '/reviews/:id'

// роуты бронирования
export const RESERVATIONS_URL = '/reservations'
export const RESERVATIONS_THREAD_URL = '/reservations/thread/:id'

// роуты оплаты
export const PAYMENT_URL = '/payment'
export const ACCOM_PAYMENT_URL = '/payment/:id'

export const MORE_URL = '/more'

// роуты поддержки
export const PROFILE_URL = 'https://www.kudanamore.ru/user-account/'

export const OLD_LK_URL = 'https://www.kudanamore.ru/account/objects/'

export const RUSTORE_APP_URL = 'https://www.rustore.ru/catalog/app/org.rentee.mobile'

// роуты авторизации
export const LOGIN_URL = '/login'
export const RESTORE_PASSWORD_URL = '/restore_password'
export const SIGN_UP_URL = '/register'

// роуты ошибок
export const ERROR_URL = '/error'
export const PERMISSION_ERROR_URL = '/permission_error'
export const NOT_FOUND_ERROR_URL = '/not_found'


export const GET_CSRF_TOKEN = '/v1/refresh_csrf/'