import { ImagesFormats } from '../placeMedia/get'


export const URL = '/v1/account/objects/'

export type Req = {
  page: number
  per_page: number
}

export type Accomodation = {
  id: number
  accomcategory: number
  accom_type: string
  name: string
  city: number
  url: string
  address: string
  latitude: number
  longitude: number
  description: string | null
  status: number
  status_verbose: string
  is_private_house: boolean
  receive_email_msg: boolean
  show_email: boolean
  thumbnail_big_url: string
  thumbnail_medium_url: string
  thumbnail_small_url: string
  thumbnail_url: string
  service_end_date: string | null
  images: ImagesFormats
  reviews_count: number
}

export type Resp = {
  count: number
  next: string
  previous: string
  results: Accomodation[]
  total_pages: number
}
