import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { snackbarStore } from 'src/stores/snackbarStore'
import { VARS } from 'src/styles/vars'
import { useIsMobile } from 'src/common/hooks/useIsMobile'


export interface SnackbarManagerProps {}

export const SnackbarManager: React.FC<SnackbarManagerProps> = observer(() => {
  const isMobile = useIsMobile()

  if (snackbarStore.snackbar) {
    const action = snackbarStore.snackbarButtonProps
      ? (
        <>
          <Button
            variant={snackbarStore.snackbarButtonProps.variant || 'text'}
            sx={
              {
                ...snackbarStore.snackbarButtonProps.sx,
                color: VARS.PRIMARY_LIGHT,
              }
            }
            {...snackbarStore.snackbarButtonProps}
          >
            { snackbarStore.snackbarButtonProps.children }
          </Button>
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={snackbarStore.closeSnackbar}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      )
      : undefined

    return (
      <Snackbar
        open
        onClose={snackbarStore.closeSnackbar}
        action={action}
        anchorOrigin={
          {
            horizontal: isMobile ? 'center' : 'left',
            vertical: 'bottom',
          }
        }
        {...snackbarStore.snackbar}
      />
    )
  }

  return null
})