export type TPlacePhone = {
  id: number
  phone: string
  comment: string
  has_viber: boolean
  has_whatsapp: boolean
  has_telegram: boolean
  l_order: number
}

export enum PlaceContactsMessengersEnum {
  WHATSAPP = 'WhatsApp',
  VIBER = 'Viber',
  TELEGRAM = 'Telegram',
}

export const RECOVER_PHONE_URL = 'v1/account/objects/:id/phones/:phoneId/undelete/'
export type RecoverPhoneResp =  Omit<TPlacePhone, 'id'> & { pk: number }

export const GET_PHONES_URL = 'v1/account/objects/:id/phones/'
export type GetPhonesResp = { results: TPlacePhone[] }
