import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@mui/styles'

import { CommonDialog } from 'src/common/components/CommonDialog/CommonDialog'
import { errorModalStore } from 'src/stores/errorModalStore'
import { useIsMobile } from 'src/common/hooks/useIsMobile'


const useStyles = makeStyles({ content: { whiteSpace: 'pre-wrap' } })

export const ErrorModal: React.FC = observer(() => {
  const {
    isOpen,
    closeModal,
    content,
    title,
  } = errorModalStore

  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={closeModal}
      onAdmit={closeModal}
      title={isMobile || !title ? 'Ошибка' : title}
      cancelBtnProps={{ style: { display: 'none' } }}
      contentSx={{ marginTop: isMobile ? 3 : 0 }}
      admitBtnProps={
        {
          children: 'Закрыть',
          variant: 'contained',
          color: 'secondary',
        }
      }
      disableMobileFullscreen
    >
      <div
        className={classes.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </CommonDialog>
  )
})