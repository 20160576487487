/* eslint-disable */
import { Theme, Interpolation, snackbarClasses, bottomNavigationActionClasses, dialogContentClasses } from '@mui/material'
import { isWindows } from 'src/utils/common'
import { VARS } from './vars'


export const globalStyles: Interpolation<Theme> = (theme: Theme) => ({
  'html, body': {
    margin: 0,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily: `'Roboto', sans-serif`,
  },
  html: {
    height: '100%',
    width: '100%',
  },
  body: {
    height: '100%',
    width: '100%',
    minWidth: 360,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  '#root': {
    height: '100%',
  },
  '#carrotquest-messenger-collapsed-container div#chat-container': {
    [theme.breakpoints.down('lg')]: { 
      display: 'none !important',
    }
  },
  img: {
    display: 'block',
    width: '100%',
  },
  div: {
    boxSizing: 'border-box',
  },
  a: {
    textDecoration: 'none',
  },
  button: {
    outline: 'none',
    '&:hover': { outline: 'none' },
    '&:focus': { outline: 'none' },
  },

  [`.${snackbarClasses.root}`]: {
    [`&.${snackbarClasses.anchorOriginBottomCenter}`]: {
      [theme.breakpoints.down('lg')]: { bottom: 64 },
    },
  },
  [`.${bottomNavigationActionClasses.label}`]: {
    fontSize: '11px !important',
  },

  '.apexcharts-tooltip': {
    fontWeight: 'bold',
    marginTop: -20,
    marginLeft: -10,
    background: 'none !important',
    border: 'none !important',
    boxShadow: 'none !important',
  },
})
