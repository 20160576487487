import qs from 'querystring'

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@mui/styles'

import { CommonDialog } from 'src/common/components/CommonDialog/CommonDialog'
import { hintModalStore } from 'src/stores/hintModalStore'
import { useIsMobile } from 'src/common/hooks/useIsMobile'


const useStyles = makeStyles(() => ({
  article: {
    '& p': {
      marginTop: '20px',
      marginBottom: 0,
      lineHeight: 1.5,
      '&:first-child': { marginTop: 0 },
      '&:last-child': { marginBottom: 0 },
    },
    '& ul': {
      lineHeight: 1.5,
      marginTop: '20px',
      marginBottom: 0,
      paddingLeft: '30px',
    },
  },
}))

export const HintModal: React.FC = observer(() => {
  const {
    isOpen,
    isLoading,
    openHintModal,
    closeHintModal,
    content,
    title,
  } = hintModalStore

  const classes = useStyles()

  const isMobile = useIsMobile()
  const location = useLocation()


  useEffect(() => {
    const query = qs.parse(location.search.slice(1, location.search.length))
    
    if (!isLoading && query.article) {
      openHintModal(
        { slug: Array.isArray(query.article) ? query.article[0] : query.article },
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <CommonDialog
      isOpen={isOpen}
      isContentLoading={isLoading}
      onClose={closeHintModal}
      onAdmit={closeHintModal}
      title={isMobile ? 'Помощь' : title}
      cancelBtnProps={{ style: { display: 'none' } }}
      contentSx={{ marginTop: isMobile ? 3 : 0 }}
      admitBtnProps={
        {
          children: 'Закрыть',
          variant: 'contained',
          color: 'secondary',
        }
      }
    >
      <div
        className={classes.article}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </CommonDialog>
  )
})