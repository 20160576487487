/* eslint-disable max-len */
import axios from 'axios'


import * as Apitypes from './api'
import { ApiClass, AppAxiosRequestConfig } from './ApiClass'


export type ReplaceUrl = { [key: string]: string | number }

export type Api = {
  get(url: typeof Apitypes.Countries.URL): Promise<Apitypes.Countries.Resp>
  get(url: typeof Apitypes.Regions.URL, data: Apitypes.Regions.Req): Promise<Apitypes.Regions.Resp>
  get(url: typeof Apitypes.ResortsList.URL, data: Apitypes.ResortsList.Req): Promise<Apitypes.ResortsList.Resp>
  get(url: typeof Apitypes.ResortsList.GET_RESORT_URL, data: Apitypes.ResortsList.ResortReq, options: AppAxiosRequestConfig): Promise<Apitypes.ResortsList.ResortResp>
  
  post(url: typeof Apitypes.Places.Create.URL, data: Apitypes.Places.Create.Req): Promise<Apitypes.Places.Create.Resp>
  get(url: typeof Apitypes.Places.List.URL, data: Apitypes.Places.List.Req): Promise<Apitypes.Places.List.Resp>
  get(url: typeof Apitypes.Places.Get.URL, data: {}, options: AppAxiosRequestConfig): Promise<Apitypes.Places.Get.Resp>
  get(url: typeof Apitypes.Places.Get.APARTMENT_URL, data: {}, options: AppAxiosRequestConfig): Promise<Apitypes.Places.Get.Resp>
  patch(url: typeof Apitypes.Places.Update.URL, data: {}, options: AppAxiosRequestConfig): Promise<Apitypes.Places.Update.Resp>
  post(url: typeof Apitypes.Places.Create.URL, data: Apitypes.Places.Create.Req): Promise<Apitypes.Places.Create.Resp>

  get(url: typeof Apitypes.PlaceMedia.Get.URL, data: {}, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.Get.Resp>
  post(url: typeof Apitypes.PlaceMedia.UploadMedia.URL, data: Apitypes.PlaceMedia.UploadMedia.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.UploadMedia.Resp>
  patch(url: typeof Apitypes.PlaceMedia.MakePrimary.URL, data: Apitypes.PlaceMedia.MakePrimary.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.MakePrimary.Resp>
  patch(url: typeof Apitypes.PlaceMedia.RotateImage.URL, data: Apitypes.PlaceMedia.RotateImage.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.RotateImage.Resp>
  patch(url: typeof Apitypes.PlaceMedia.UpdateDescription.URL, data: Apitypes.PlaceMedia.UpdateDescription.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.UpdateDescription.Resp>
  patch(url: typeof Apitypes.PlaceMedia.UndeleteMedia.URL, data: Apitypes.PlaceMedia.UndeleteMedia.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.UndeleteMedia.Resp>
  delete(url: typeof Apitypes.PlaceMedia.DeleteMedia.URL, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.DeleteMedia.Resp>
  patch(url: typeof Apitypes.PlaceMedia.ReorderPhoto.URL, data: Apitypes.PlaceMedia.ReorderPhoto.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceMedia.ReorderPhoto.Resp>

  get(url: typeof Apitypes.PlaceServices.Get.GET_PLACE_SERVICES, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Get.GetPlaceServicesResp>
  get(url: typeof Apitypes.PlaceServices.Get.GET_PLACE_DESCRIPTION_URL, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Get.GetPlaceDesciptionResp>
  get(url: typeof Apitypes.PlaceServices.Get.GET_POLICY_URL, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Get.GetPolicyResp>
  get(url: typeof Apitypes.PlaceServices.Get.GET_PLACE_CATEGORY, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Get.GetPlaceCategoryResp>
  get(url: typeof Apitypes.PlaceStatistics.Get.GET_PLACE_STATISTICS, data: Apitypes.PlaceStatistics.Get.GetPlaceStatisticsReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceStatistics.Get.GetPlaceStatisticsResp>
  
  get(url: typeof Apitypes.PlaceReservations.Get.GET_ACCOMMODATIONS_INFO, data: Apitypes.PlaceReservations.Get.GetAccommodationsInfoReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReservations.Get.AccommodationsInfoRes>
  get(url: typeof Apitypes.PlaceReservations.Get.GET_RESERVATIONS_INFO, data: Apitypes.PlaceReservations.Get.GetReservationsInfoReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReservations.Get.ReservationsInfoRes>
  options(url: typeof Apitypes.PlaceReservations.Get.GET_RESERVATIONS_INFO, data: {}, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReservations.Get.ReservationOptionsInfoRes>
  put(url: typeof Apitypes.PlaceReservations.Update.UPDATE_RESERVATION, data: Apitypes.PlaceReservations.Update.UpdateReservationReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReservations.Update.UpdateReservationRes>
  post(url: typeof Apitypes.PlaceReservations.Create.CREATE_RESERVATION, data: Apitypes.PlaceReservations.Create.CreateReservationReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReservations.Create.CreateReservationRes>
  delete(url: typeof Apitypes.PlaceReservations.Delete.DELETE_RESERVATION, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReservations.Delete.DeleteReservationRes>
  
  get(url: typeof Apitypes.PlaceReviews.Get.GET_REVIEWS, data: Apitypes.PlaceReviews.Get.GetReviewsReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReviews.Get.GetReviewsRes>
  patch(url: typeof Apitypes.PlaceReviews.Reply.PATCH_REPLY, data: Apitypes.PlaceReviews.Reply.PatchReplyReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceReviews.Reply.GetReviewsRes>

  patch(url: typeof Apitypes.PlaceServices.Edit.EDIT_PLACE_SERVICE_URL, data: Apitypes.PlaceServices.Edit.EditPlaceServiceReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Edit.EditPlaceServiceResp>
  patch(url: typeof Apitypes.PlaceServices.Edit.EDIT_PLACE_CATEGORY, data: Apitypes.PlaceServices.Edit.EditPlaceCategoryReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Edit.EditPlaceCategoryResp>
  patch(url: typeof Apitypes.PlaceServices.Edit.EDIT_PLACE_DESCRIPTION_URL, data: Apitypes.PlaceServices.Edit.EditPlaceDesciptionReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Edit.EditPlaceDesciptionResp>
  patch(url: typeof Apitypes.PlaceServices.Edit.EDIT_PLACE_PRIVACY, data: Apitypes.PlaceServices.Edit.EditPlacePrivacyReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Edit.EditPlacePrivacyResp>
  patch(url: typeof Apitypes.PlaceServices.Edit.EDIT_POLICY_URL, data: Apitypes.PlaceServices.Edit.EditPolicyReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceServices.Edit.EditPolicyResp>
  patch(url: typeof Apitypes.PlaceServices.Edit.EDIT_PLACE_ROOMS, data: Apitypes.PlaceServices.Edit.EditPlaceRoomsReq, options: AppAxiosRequestConfig): Promise<Apitypes.Places.Get.Resp>

  get(url: typeof Apitypes.PlaceContacts.Get.GET_PHONES_URL, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceContacts.Get.GetPhonesResp>
  patch(url: typeof Apitypes.PlaceContacts.Get.RECOVER_PHONE_URL, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceContacts.Get.RecoverPhoneResp>

  post(url: typeof Apitypes.PlaceContacts.Create.ADD_PHONE_URL, data: Apitypes.PlaceContacts.Create.AddPhoneReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceContacts.Create.AddPhoneResp>
  patch(url: typeof Apitypes.PlaceContacts.Create.ADD_SMS_NOTIFY_PHONE_URL, data: Apitypes.PlaceContacts.Create.AddSmsNotifyPhoneReq): Promise<void>

  delete(url: typeof Apitypes.PlaceContacts.Delete.DELETE_PHONE_URL, options: AppAxiosRequestConfig): Promise<void>

  patch(url: typeof Apitypes.PlaceContacts.Edit.EDIT_PHONE_URL, data: Apitypes.PlaceContacts.Edit.EditPhoneReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceContacts.Get.TPlacePhone>
  patch(url: typeof Apitypes.PlaceContacts.Edit.EDIT_LINK_URL, data: Apitypes.PlaceContacts.Edit.EditLinkReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceContacts.Edit.EditLinkResp>
  
  patch(url: typeof Apitypes.PlaceContacts.Update.PATCH_RECEIVE_MESSEGASES_URL, data: Apitypes.PlaceContacts.Update.PatchReceiveMessagesReq, options: AppAxiosRequestConfig): Promise<void>
  patch(url: typeof Apitypes.PlaceContacts.Update.PATCH_SHOW_EMAIL_URL, data: Apitypes.PlaceContacts.Update.PatchShowEmailReq, options: AppAxiosRequestConfig): Promise<void>

  get(url: typeof Apitypes.HelpArticles.Get.GET_ARTICLE_BY_SLUG_URL, data: Apitypes.HelpArticles.Get.GetArticleBySlugReq, options: AppAxiosRequestConfig): Promise<Apitypes.HelpArticles.Get.GetArticleBySlugResp>

  get(url: typeof Apitypes.PlaceAccomodations.CategoriesList.URL, data: Apitypes.PlaceAccomodations.CategoriesList.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.CategoriesList.Resp>
  get(url: typeof Apitypes.PlaceAccomodations.GetCategory.URL, data: Apitypes.PlaceAccomodations.GetCategory.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.GetCategory.Resp>
  delete(url: typeof Apitypes.PlaceAccomodations.BatchDelete.URL, options: AppAxiosRequestConfig, data: Apitypes.PlaceAccomodations.BatchDelete.Req): Promise<Apitypes.PlaceAccomodations.BatchDelete.Resp>
  patch(url: typeof Apitypes.PlaceAccomodations.BatchUndelete.URL, data: Apitypes.PlaceAccomodations.BatchUndelete.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.BatchUndelete.Resp>
  patch(url: typeof Apitypes.PlaceAccomodations.BatchMove.URL, data: Apitypes.PlaceAccomodations.BatchMove.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.BatchMove.Resp>
  post(url: typeof Apitypes.PlaceAccomodations.CreateCategory.URL, data: Apitypes.PlaceAccomodations.CreateCategory.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.CreateCategory.Resp>
  get(url: typeof Apitypes.PlaceAccomodations.Rooms.URL, data: Apitypes.PlaceAccomodations.Rooms.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.Rooms.Resp>
  patch(url: typeof Apitypes.PlaceAccomodations.UpdateCategory.URL, data: Apitypes.PlaceAccomodations.UpdateCategory.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.UpdateCategory.Resp>
  delete(url: typeof Apitypes.PlaceAccomodations.UpdateCategory.URL, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.DeleteCategory.Resp>
  patch(url: typeof Apitypes.PlaceAccomodations.ReorderCategories.URL, data: Apitypes.PlaceAccomodations.ReorderCategories.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.ReorderCategories.Resp>
  post(url: typeof Apitypes.PlaceAccomodations.CreateRooms.URL, data: Apitypes.PlaceAccomodations.CreateRooms.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.CreateRooms.Resp>
  patch(url: typeof Apitypes.PlaceAccomodations.UpdateRoom.URL, data: Apitypes.PlaceAccomodations.UpdateRoom.Req, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.UpdateRoom.Resp>
  delete(url: typeof Apitypes.PlaceAccomodations.DeleteRoom.URL, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.DeleteRoom.Resp>
  get(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.GET_WORKING_PERIODS_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.WorkingPeriodsReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.WorkingPeriodsRes>
  post(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.INIT_WORKING_PERIODS_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.InitWorkingPeriodReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.InitWorkingPeriodRes>
  post(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.INIT_WORKING_PERIODS_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.CopyWorkingPeriodFromYearReq, options: AppAxiosRequestConfig): Promise<void>
  patch(url: typeof Apitypes.PlaceAccomodations.UndeleteCategory.URL, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.CategoriesList.ServerCategory>
  
  delete(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.DELETE_WORKING_PERIOD_URL, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.DeleteWorkingPeriodRes>
  post(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.ADD_WORKING_PERIODS_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.AddWorkingPeriodReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.AddWorkingPeriodRes>
  post(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.SPLIT_WORKING_PERIODS_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.SplitWorkingPeriodReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.SplitWorkingPeriodRes>
  get(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.GET_WORKING_PERIODS_PRICES_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.GetWorkingPeriodPricesReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.GetWorkingPeriodPricesRes>
  get(url: typeof Apitypes.PlaceAccomodations.ApartmentPrices.URL, data: Apitypes.PlaceAccomodations.ApartmentPrices.GetApartmentPricesRequest, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.ApartmentPrices.GetApartmentPricesResponse>
  patch(url: typeof Apitypes.PlaceAccomodations.ApartmentPrices.UPDATE_APARTMENT_URL, data: Apitypes.PlaceAccomodations.ApartmentPrices.UpdateApartmentPricesResponse, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.ApartmentPrices.GetApartmentPricesResponse>
  patch(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.UPDATE_WORKING_PERIODS_PRICES_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.UpdateWorkingPeriodPricesReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.UpdateWorkingPeriodPricesRes>
  patch(url: typeof Apitypes.PlaceAccomodations.WorkingPeriods.UPDATE_WORKING_PERIOD_URL, data: Apitypes.PlaceAccomodations.WorkingPeriods.UpdateWorkingPeriodReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.WorkingPeriods.UpdateWorkingPeriodRes>

  get(url: typeof Apitypes.PlaceAccomodations.Discounts.GET_DISCOUNTS_URL, data: Apitypes.PlaceAccomodations.Discounts.GetDiscountsReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.Discounts.GetDiscountsRes>
  post(url: typeof Apitypes.PlaceAccomodations.Discounts.ADD_DISCOUNTS_URL, data: Apitypes.PlaceAccomodations.Discounts.AddDiscountsReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.Discounts.AddDiscountsRes>
  patch(url: typeof Apitypes.PlaceAccomodations.Discounts.UPDATE_DISCOUNTS_URL, data: Apitypes.PlaceAccomodations.Discounts.UpdateDiscountsReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.Discounts.UpdateDiscountsRes>
  delete(url: typeof Apitypes.PlaceAccomodations.Discounts.DELETE_DISCOUNTS_URL, options: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.Discounts.DeleteDiscountsRes>

  get(url: typeof Apitypes.TouristRequests.Get.GET_TOURIST_REQUESTS, data: Apitypes.TouristRequests.Get.GetRequestsReq, options: AppAxiosRequestConfig): Promise<Apitypes.TouristRequests.Get.GetRequestsResp>
  get(url: typeof Apitypes.TouristRequests.Get.GET_TOURIST_REQUESTS_COUNT, options?: AppAxiosRequestConfig): Promise<Apitypes.TouristRequests.Get.GetRequestsCountResp>
  get(url: typeof Apitypes.TouristRequests.Get.GET_TOURIST_REQUEST, data: undefined, options: AppAxiosRequestConfig): Promise<Apitypes.TouristRequests.Get.GetRequestResp>
  post(url: typeof Apitypes.TouristRequests.Add.ADD_OWNER_MESSAGE, data: Apitypes.TouristRequests.Add.AddOwnerMessageReq, options: AppAxiosRequestConfig): Promise<Apitypes.TouristRequests.Add.AddOwnerMessageReq>
  post(url: typeof Apitypes.TouristRequests.Add.MARK_READ, data: Apitypes.TouristRequests.Add.MarkReadReq, options: AppAxiosRequestConfig): Promise<Apitypes.TouristRequests.Add.MarkReadReq>

  get(url: typeof Apitypes.PlacePayment.Get.GET_PAYMENTS_LIST, data: Apitypes.PlacePayment.Get.GetPaymentsListReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlacePayment.Get.GetPaymentsListRes>
  post(url: typeof Apitypes.PlacePayment.Post.SEND_PAYMENT_METHOD, data: Apitypes.PlacePayment.Post.SendPaymentMethodReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlacePayment.Post.SendPaymentMethodRes>
  get(url: typeof Apitypes.PlacePayment.Get.GET_PAYMENT, data: Apitypes.PlacePayment.Get.GetPaymentsListReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlacePayment.Get.GetPaymentsListRes['results'][number]>
  post(url: typeof Apitypes.PlacePayment.Get.CREATE_PAYMENT, data: Apitypes.PlacePayment.Get.GetPaymentsListReq, options: AppAxiosRequestConfig): Promise<Apitypes.PlacePayment.Get.GetPaymentsListRes['results'][number]>

  get(url: typeof Apitypes.Auth.GetAuth.URL, data: Apitypes.Auth.GetAuth.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.Auth.GetAuth.Resp>
  get(url: typeof Apitypes.PlaceAccomodations.GetCount.URL, data: Apitypes.PlaceAccomodations.GetCount.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.PlaceAccomodations.GetCount.Resp>
  post(url: typeof Apitypes.Auth.SetPassword.URL, data: Apitypes.Auth.SetPassword.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.Auth.SetPassword.Resp>
  post(url: typeof Apitypes.Auth.Login.URL, data: Apitypes.Auth.Login.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.Auth.Login.Resp>
  post(url: typeof Apitypes.Auth.SignUp.URL, data: Apitypes.Auth.SignUp.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.Auth.SignUp.Resp>
  post(url: typeof Apitypes.Auth.RestorePassword.URL, data: Apitypes.Auth.RestorePassword.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.Auth.RestorePassword.Resp>
  get(url: typeof Apitypes.Auth.GetUserInfo.URL, data: Apitypes.Auth.GetUserInfo.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.Auth.GetUserInfo.Resp>
  post(url: typeof Apitypes.Auth.Logout.URL, data: Apitypes.Auth.Logout.Req, options?: AppAxiosRequestConfig): Promise<Apitypes.Auth.Logout.Resp>
  
  get(url: typeof Apitypes.PromoReviews.Get.URL, data: {}, options?: AppAxiosRequestConfig): Promise<Apitypes.PromoReviews.Get.Resp>
}

export const API_URL = 'https://dev2.kudanamore.ru'

export const toQS =
  (d: any) =>
    Object.keys(d).map((k) => `${k}=${encodeURIComponent(d[k])}`).join('&')

export const apiAxios = axios.create({
  auth: {
    password: 'kudanamore',
    username: 'user1',
  },
  baseURL: '/rest_api',
  headers: { 'Content-Type': 'application/json' },
})

export const api = new ApiClass(apiAxios) as Api
