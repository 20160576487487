export type THelpArticle = {
  name: string
  slug: string
  html_title:	string
  visible: boolean
  // html string
  content: string
}

export const GET_ARTICLE_BY_SLUG_URL = '/v1/help_articles/:slug/'
export type GetArticleBySlugReq = void
export type GetArticleBySlugResp = THelpArticle