import { ImagesFormats, PlaceMedia } from '../placeMedia/get'


export const URL = '/v1/account/objects/:id/room_types/'

export type ServerCategory = Category & { list_images: ServerCategoryImage[] }

export type ServerCategoryImage = {
  angle: number
  id: number
  img_url: string
  order: number
  primary: boolean
  thumbnail_big_url: string
  thumbnail_medium_url: string
  thumbnail_small_url: string
  thumbnail_url: string
  description: string
  images: ImagesFormats
}

export type Category = {
  balcon: boolean
  conditioner: number
  cost_type: number
  description: string
  extra_fields: unknown
  extra_person_qty: number
  floor: unknown
  fridge: boolean
  furniture: unknown
  id: number
  kitchen: boolean
  l_order: number
  modify_date: string
  name: string
  person_qty: number
  room_qty: number
  shower: number
  square: number
  toilet: number
  tv: boolean
  list_images: PlaceMedia[]
}

export type Req = void

export type Resp = {
  count: number
  next: null | string
  previous: null | string
  total_pages: number
  results: ServerCategory[]
}