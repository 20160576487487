import * as Apitypes from 'src/network/api'
import { Category } from 'src/network/api/placeAccomodations/categoriesList'


export function descOrderSort<T extends { l_order: number }>(items: T[]): T[] {
  return items.sort((a, b) => b.l_order - a.l_order)
}

export function resetWindowScroll() {
  window.scrollTo(0, 0)
}

export function isWindows() {
  return navigator.platform.includes('Win')
}

export function sortPhotos(photos: Apitypes.PlaceMedia.Get.PlaceMedia[]) {
  const sortedPhotos: Apitypes.PlaceMedia.Get.PlaceMedia[] = []

  photos.forEach((p) => {
    if (p.primary) {
      sortedPhotos.unshift(p)
    } else {
      sortedPhotos.push(p)
    }
  })
  return sortedPhotos
}

export function sortImagesRoomType(roomType: Category) {
  const isNotOrderedImages = roomType.list_images.every((x) => x.order === 0)
  const sorted = roomType.list_images.sort((a, b) => isNotOrderedImages ? b.id - a.id : b.order - a.order)
  return { ...roomType, list_images: sortPhotos(sorted) }
}

export const isCsrfError = (err: any) => {
  const variant1 = err?.errors?.[0]?.code
  if (variant1) {
    return variant1.includes('csrf_error')
  }
  const variant2 = err?.detail
  if (variant2) {
    return variant2.includes('CSRF Failed')
  }
}