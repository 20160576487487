export type Room = {
  id: number
  room_type: number
  number: number
  floor: number
  building: number
  notes: string
}

export const URL = '/v1/account/objects/:id/rooms/'

export type Req = void

export type Resp = {
  results: Room[]
}