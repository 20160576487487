import { PlaceMedia } from './get'


export const URL = '/v1/account/objects/:id/media/'

export type Req = {
  type: 1
  room_type?: number
  description?: string
  order?: number
  img: {
    file_name: string
    encoded_str: string
  }
} | {
  type: 2
  room_type?: number
  description?: string
  order?: number
  video_url: string
}

export type Resp = PlaceMedia