import { Room } from './getRooms'


export const URL = '/v1/account/objects/:id/rooms/'

export type Req = {
  room_type: number
  number: number
  floor: number
  notes: string
}[]

export type Resp = Room[]