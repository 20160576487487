/* eslint-disable max-len */

export type PlaceStatistics = {
  statDate: number
  pageViews: number
}[]

export type GetPlaceStatisticsResp = {
  count: number
  next: string
  previous: string
  results: {
    id: number
    stat_date: string
    page_views: number
    page_previews: number
    metrika_views: number
  }[]
}

export type GetPlaceStatisticsReq = { per_page: number, page: number, stat_date__range: string }

export const GET_PLACE_STATISTICS = 'v1/account/objects/:placeId/day_stat'
