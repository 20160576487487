export const URL = '/v1/auth/register/'

export type Req = {
  email: string
  password: string
  first_name: string
  last_name: string
  captcha: string
}

export type Resp = {
  success: boolean
}
