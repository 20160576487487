import { AppAxiosRequestConfig } from 'src/network/ApiClass'
import { api } from 'src/network/http'


export const setTimeoutPromise = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const isObject = (value: any) => {
  return (typeof value === 'object' || typeof value === 'function') && (value !== null)
}
 
export const deepEqual = <T1 extends object, T2 extends object>(object1: T1, object2: T2): boolean => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = (object1 as any)[key]
    const val2 = (object2 as any)[key]

    const areObjects = isObject(val1) && isObject(val2)

    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false
    }
  }
  return true
}

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const loadDataWithPagination = async (
  url: string,
  perPage: number,
  params?: any,
  options?: AppAxiosRequestConfig,
) => {
  const reqParams = params || {}
  
  const resp: any = await api.get(
    url as any,
    {
      ...reqParams,
      per_page: perPage,
      page: 1,
    },
    options || {},
  )

  const { count, results } = resp

  const data: any[] = results
  
  for (let i = 1; i < Math.ceil(count / perPage); i++) {
    const resp: any = await api.get(
      url as any,
      {
        ...reqParams,
        per_page: perPage,
        page: i + 1,
      },
      options || {},
    )
    
    data.push(...resp.results)
  }

  return data
}

export const apiErrorsToClient = (
  data: { errors: { code: string, detail: string }[] },
  spareMessage: string,
  ifArrayMessage: string,
) => {
  try {
    const { errors } = data

    if (Array.isArray(errors)) {
      return errors.map((value) => value.detail)
    }

    return [ifArrayMessage]
  } catch {
    return [spareMessage]
  }
}