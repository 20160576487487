import { makeAutoObservable } from 'mobx'
import { format } from 'date-fns'

import { api } from 'src/network/http'
import * as Apitypes from 'src/network/api'
import { Source } from 'src/App/Reservations/components/SourceSwitch'


export class TouristReqestsStore {
  loading: boolean = false
  isRequestLoading: boolean = false
  archivingId: number | null = null
  page: number = 1

  currentRequest: Apitypes.TouristRequests.Get.GetRequestResp | null = null

  touristRequestsMap: Record<Source, Apitypes.TouristRequests.Get.TouristRequest[]> = {
    actual: [],
    all: [],
    unread: [],
  }

  countMap: Record<Source, number> = {
    actual: 0,
    all: 0,
    unread: 0,
  }
  
  constructor() {
    makeAutoObservable(this)
  }

  getTouristRequestsCount = async (source: Source) => {
    const response = await api.get(
      Apitypes.TouristRequests.Get.GET_TOURIST_REQUESTS_COUNT,
    )
    this.countMap[source] = response[source]
  }

  getTouristRequestsList = async (page: number, source: Source, disableLoading?: boolean) => {
    if (!disableLoading) {
      this.loading = true
    }
 
    const response = await api.get(
      Apitypes.TouristRequests.Get.GET_TOURIST_REQUESTS,
      {
        page,
        per_page: 20,
        ...(source === 'actual' ? { request__checkout_date__gt: format(new Date(), 'Y-MM-dd'), hidden: false } : {}),
        ...(source === 'unread' ? { has_unread_messages: true } : {}),
      },
      {},
    )
    this.page = page

    this.touristRequestsMap[source] = response.results.map(
      (v) => ({
        ...v,
        room: v.room || v.room_type,
        username: v.username || v.contact_person,
        room_name: v.room_name || v.room_type_name,
      }),
    )
    this.countMap[source] = response.count
    this.loading = false
  }

  getTouristRequest = async (requestId: string, disableLoading?: boolean) => {
    if (!disableLoading) {
      this.isRequestLoading = true
    }

    this.currentRequest = await api.get(
      Apitypes.TouristRequests.Get.GET_TOURIST_REQUEST,
      undefined,
      { replaceUrl: { request_id: requestId } },
    ).then((res) => {
      this.isRequestLoading = false
      return {
        ...res,
        room: res.room || res.room_type,
        username: res.username || res.contact_person,
        room_name: res.room_name || res.room_type_name,
      }
    })

    return this.currentRequest
  }

  addMessage = async (requestId: string, message: string) => {
    await api.post(
      Apitypes.TouristRequests.Add.ADD_OWNER_MESSAGE,
      { message },
      { replaceUrl: { request_id: requestId } },
    ).then((res) => {
      return res
    })
  }

  markRead = async (requestId: string) => {
    await api.post(
      Apitypes.TouristRequests.Add.MARK_READ,
      {},
      { replaceUrl: { request_id: requestId } },
    )
    await this.getTouristRequestsCount('unread')
  }
}

export const touristReqestsStore = new TouristReqestsStore()