import { makeAutoObservable } from 'mobx'

import { api } from 'src/network/http'
import * as Apitypes from 'src/network/api'


import { authStore } from './authStore'


class AppStore {
  isAppLoaded = false
  accomodationsCount = 5245
  promoReviews: Apitypes.PromoReviews.Get.OwnerReview[] = []

  constructor() {
    makeAutoObservable(this)
  }

  loadApp = async () => {
    await authStore.checkIsUserLoggedIn()

    this.isAppLoaded = true
  }

  getAccomodationsCount = async () => {
    const resp = await api.get(Apitypes.PlaceAccomodations.GetCount.URL)

    this.accomodationsCount = resp.count
  }

  getPromoReviews = async () => {
    const resp = await api.get(Apitypes.PromoReviews.Get.URL, {
      ordering: '-id',
      per_page: '9',
      page: '1',
    })
    
    this.promoReviews = resp.results
  }
}

export const appStore = new AppStore()