import React from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@mui/styles'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import * as Sentry from '@sentry/react'

import { THEME } from 'src/utils/themeConfig'
import { SnackbarManager } from 'src/common/components/_global/SnackbarManager/SnackbarManager'
import { AppStyles } from 'src/common/components/_global/AppStyles/AppStyles'
import { HintModal } from 'src/common/components/_global/HintModal/HintModal'
import { ErrorModal } from 'src/common/components/_global/ErrorModal/ErrorModal'
import { Backdrop } from 'src/common/components/_global/Backdrop/Backdrop'
import { useMount } from 'src/common/hooks/useMount'
import { appStore } from 'src/stores/appStore'

import { AppRoutes } from './AppRoutes'
import { carrotQuestScript } from './carrotQuestScript'


Sentry.init({
  dsn: 'https://72b1d469ba476b3cf669bc7c6d69b8ea@o4507565441482752.ingest.de.sentry.io/4507565445152848',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // eslint-disable-next-line max-len
  tracePropagationTargets: ['localhost', /^https:\/\/lk\.kudanamore\.ru/, /^https:\/\/lk\.kudanamore\.ru\/rest_api\/v1\/api/],
  // Session Replay
  // eslint-disable-next-line max-len
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // eslint-disable-next-line max-len
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})


const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}), { name: 'App' })

export const App: React.FC = () => {
  const classes = useStyles()

  useMount(() => {
    appStore.loadApp()
    moment.locale('ru')
    moment.updateLocale('ru', {
      weekdaysShort: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
      weekdaysMin: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
      weekdays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
      monthsShort : ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
    })
  })

  const isAuthPage =
    window.location.pathname.includes('login') ||
    window.location.pathname.includes('register') ||
    window.location.pathname.includes('error')
  
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={THEME}>
        <div className={classes.container}>
          <Helmet>
            <title>Куда на море</title>

            {
              !isAuthPage && (
                <script type='text/javascript'>
                  { carrotQuestScript }
                </script>
              )
            }
          </Helmet>
          <AppRoutes>
            { /* глобальные компоненты, доступные по всему приложению */ }
            <AppStyles />
            <SnackbarManager />
            <HintModal />
            <ErrorModal />
          </AppRoutes>
          <Backdrop />
          <SnackbarManager />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>

  )
}

export default App