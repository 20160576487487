export const URL = 'v1/users/profile/'

export type Req = void

export type Resp = {
  avatar: unknown
  avatar_thumbnail_url: string
  date_joined: string
  email: string
  first_name: string
  id: number
  is_authenticated: boolean
  is_manager: boolean
  last_name: string
  permissions: unknown[]
  sms_phone: string
}
