export const transitionNormal = '.2s ease-out'

export const animate = (...args: any) => {
  let animatedProperty = ''

  if (args.length > 0) {
    args.forEach((a: any, index: number) => {
      animatedProperty += `${a} ${transitionNormal} ${index === args.length - 1 ? '' : ', '}`
    })
  }

  return { transition: animatedProperty }
}

export const ellipsis = () => ({
  whiteSpace: 'nowrap' as const,
  overflow: 'hidden' as const,
  textOverflow: 'ellipsis' as const,
})

export const lineCamp = (maxRows: number = 2) => ({
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': maxRows,
  overflow: 'hidden',
})

export const avatarCircle = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
  width: '40px',
  borderRadius: '50%',
})