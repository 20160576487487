export const URL = '/v1/account/objects/:id/prices/'
export const UPDATE_APARTMENT_URL = '/v1/account/objects/:id/prices/:year'

export type GetApartmentPricesRequest = {
  year: number
}

export type GetApartmentPricesResponse = {
  accomodation_prices: Price[]
}

export type UpdateApartmentPricesResponse = GetApartmentPricesResponse

export type Price = {
  id: number
  period: number
  price: number | null
}
