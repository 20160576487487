/* eslint-disable max-len */

export type AccommodationsInfoRes = {
  accomodation_id: number
  room_type_id: number
  room_type_id_path: string
  room_type_name: string
  rooms: {
    room_id: number
    room_id_path: string
    room_name: string
    room_type_name: string
    room_number: number | string
  }[] | null
}[]

export type ReservationFormat = {
  source: string
  source_name: string
  checkin_date: string
  checkin_time: string
  checkout_date: string
  checkout_time: string
  accomodation: number
  adults: number
  children: number
  contact_person: string | null
  email: string | null
  phone: string | null
  old_phone: string | null
  pk: number
  room: number
  room_id_path: string | undefined
  status: string
  status_slug: string
  status_name: string | undefined
  notes: string | undefined
  reservation_tag: number | null
  reservation_tag_name: string | null
  reservation_tag_slug: string | null
}

export type ReservationsInfoRes = {
  count: number
  next: string | null
  previous: string | null
  total_pages: 1
  results: ReservationFormat[]
}
export type GetAccommodationsInfoReq = { accomodation_ids: string }
export type GetReservationsInfoReq = { accomodation_ids: string, date: string, per_page: number, page: number }

export type SourceValue = { value: number, display_name: string }
export type ReservationOptionsInfoRes = {
  actions: {
    POST: {
      source: {
        choices: SourceValue[]
      }
    }
  }
}

export const GET_ACCOMMODATIONS_INFO = '/v1/calendar/'
export const GET_RESERVATIONS_INFO = '/v1/reservations/'

export const STATUSES = {
  notConfirmed: 'not-confirmed',
  confirmed: 'confirmed',
  stay: 'stay',
  departure: 'departure',
  reserved: 'reserved',
  cancelled: 'cancelled',
}

export const STATUS_VALUES = [
  {
    statusName: 'Не подтверждено', statusId: STATUSES.notConfirmed, statusNumber: 1,
  },
  {
    statusName: 'Подтверждено', statusId: STATUSES.confirmed, statusNumber: 2,
  },
  {
    statusName: 'Проживает', statusId: STATUSES.stay, statusNumber: 3,
  },
  {
    statusName: 'Выехал', statusId: STATUSES.departure, statusNumber: 4,
  },
  {
    statusName: 'Зарезервировано', statusId: STATUSES.reserved, statusNumber: 5,
  },
  {
    statusName: 'Отменено', statusId: STATUSES.cancelled, statusNumber: 6,
  },
]

export const TAGS = {
  nan: 'NAN',
  preOrder: 'PRE_ORDER',
  notOrdered: 'NOT_ORDERED',
  cleaningNeed: 'CLEANING_NEED',
  repair: 'REPAIR',
}

export const TAGS_VALUES = [
  { id: TAGS.nan, title: 'Не выбрана' },
  { id: TAGS.preOrder, title: 'Ждем предоплату' },
  { id: TAGS.notOrdered, title: 'Бронь не оплачена' },
  { id: TAGS.cleaningNeed, title: 'Требуется уборка' },
  { id: TAGS.repair, title: 'Ремонт' },
]