import React, { memo, useLayoutEffect, useState } from 'react'
import type { History } from 'history'
import { BrowserRouterProps, Router } from 'react-router-dom'
 

export interface AppRouterProps extends Omit<BrowserRouterProps, 'window'> {
  history: History
}

export const AppRouter: React.FC<AppRouterProps> = memo(({ history, ...otherProps }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => {
    history.listen(setState)
  }, [history])
 
  return (
    <Router
      {...otherProps}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
})
