import { TPlaceService, TPlacePolicy } from './get'


export const EDIT_PLACE_SERVICE_URL = 'v1/account/objects/:id/services/:serviceId/'
export type EditPlaceServiceResp = TPlaceService
export type EditPlaceServiceReq = Pick<TPlaceService, 'description' | 'have_facility'>


export const EDIT_PLACE_DESCRIPTION_URL = 'v1/account/objects/:id/'
export type EditPlaceDesciptionResp = { description: string | null }
export type EditPlaceDesciptionReq = { description: string | null }


export const EDIT_POLICY_URL = 'v1/account/objects/:id/policy/'
export type EditPolicyReq = Partial<TPlacePolicy>
export type EditPolicyResp = TPlacePolicy


export const EDIT_PLACE_CATEGORY = 'v1/account/hotels/:id/hotel_category/'
export type EditPlaceCategoryResp = any
export type EditPlaceCategoryReq = any

export const EDIT_PLACE_PRIVACY = 'v1/account/objects/:id/'
export type EditPlacePrivacyResp = { is_private_house: boolean }
export type EditPlacePrivacyReq = { is_private_house: boolean }

export const EDIT_PLACE_ROOMS = 'v1/account/objects/:id/'
export type EditPlaceRoomsReq = {
  apartment: { room_cnt: number, bed_cnt: number }
}
