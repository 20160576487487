import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react-lite'

import { VARS } from 'src/styles/vars'
import { useIsMobile } from 'src/common/hooks/useIsMobile'


const useStyles = makeStyles({
  container: {
    height: '60px',
    background: VARS.GRAY_100,
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const Footer = observer(() => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  if (isMobile) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography
        variant='body2'
        component='div'
        className={classes.text}
        color='text.secondary'
      >
        Поддержка по телефону:
      </Typography>
      <Typography
        variant='subtitle1'
        component='a'
        href='tel:+79683007730'
        color='text.primary'
        sx={
          {
            margin: '0 8px',
            textDecoration: 'unset',
          }
        }
      >
        +7 968 300-77-30
      </Typography>
      <Typography
        variant='body2'
        component='div'
        className={classes.text}
        color='text.secondary'
      >
        с 10:00 до 18:00
      </Typography>
    </div>
  )
})
