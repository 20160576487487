export type Discount = {
  id: number
  discount: number
  discount_type: 'percent' | 'flat'
  rooms: number[]
  start_date: string
  end_date: string
  min_nights: number
}

export type GetDiscountsReq = {
  page: number
  per_page: number
}
  
export type GetDiscountsRes = {
  count: number
  next: null | string
  previous: null | string
  total_pages: number
  results: Discount[]
}
  
export const GET_DISCOUNTS_URL = '/v1/account/objects/:accomodation_id/campaigns/'

export type AddDiscountsReq = {
  discount: number
  rooms: number[]
  old_rooms?: number[]
  start_date: string
  end_date: string
  min_nights: number
}
  
export type AddDiscountsRes = AddDiscountsReq & { pk: number }
  
export const ADD_DISCOUNTS_URL = '/v1/account/objects/:accomodation_id/campaigns/'

export type UpdateDiscountsReq = Omit<Discount, 'id'>
  
export type UpdateDiscountsRes = Discount
  
export const UPDATE_DISCOUNTS_URL = '/v1/account/objects/:accomodation_id/campaigns/:discount_id'

export type DeleteDiscountsReq = undefined
  
export type DeleteDiscountsRes = void
  
export const DELETE_DISCOUNTS_URL = '/v1/account/objects/:accomodation_id/campaigns/:discount_id'