/* eslint-disable max-len */

export type GetReviewsRes = {
  count: number
  next: null
  previous: null
  total_pages: number
  results:
  {
    id: number
    create_date: string
    fio: string
    place_from: string
    rest_date: string
    comment: string
    total_rating: number
    useful_cnt: number
    place_rating: number
    service_rating: number
    cost_quality_rating: number
    young_recomended: boolean
    romantic_recomended: boolean
    childs_recomended: boolean
    aged_recomended: boolean
    friend_group_recomended: boolean
    best: boolean
    reply: {
      create_date: string
      modify_date: string
      comment: string
    }
  }[]
  
}
    

export type GetReviewsReq = { page: number, per_page: number }

export const GET_REVIEWS = '/v1/account/objects/:accommodationId/reviews/'
