export const URL = '/v1/owner-reviews/'

export type OwnerReview = {
  create_date: string
  year: number
  user: {
    first_name: string
    last_name: string
    date_joined: string
    avatar_thumbnail_url: string
  }
  user_name: string
  accomodation: {
    id: number
    name: string
    address: string
    has_parking: boolean
    has_wifi: boolean
    has_playground: boolean
    has_pool: boolean
    thumbnail_small_url: string
    thumbnail_medium_url: string
    thumbnail_big_url: string
    site_url: string
    url: string
    preview_url: string
    city: number
    city_verbose: string
    city_synonym: string
    resort: number
    resort_verbose: string
    resort_synonym: string
    status: number
    accom_type: string
    accom_type_verbose: string
    latitude: number
    longitude: number
    yandex_map_url: string
    rating: number
    reviews_qty: number
    min_price: number
    min_price_meal: number
    min_price_meal_verbose: string
    min_price_meal_alternative: string
    min_price_month: string
    create_date: string
    is_private_house: boolean
    last_week_page_views: number
    images: {
      XL: string
      XL_webp: string
      XXL: string
      XXL_webp: string
    }
    accomcategory: {
      id: number
      name: string
      slug: string
    }
    user: string
  }
  review: string
};

export type Resp = { results: OwnerReview[] }