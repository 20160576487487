export const URL = '/v1/resorts/'
export const GET_RESORT_URL = '/v1/resorts/:resortId/'

export type Resort = {
  id: number
  name: string
  name_case: string
  title: null | string
  synonym: string
  url: string
  latitude: number
  longitude: number
  thumbnail_url: string
  thumbnail_small_url: string
  thumbnail_medium_url: string
  cover_image_url: unknown
  resort_distance: unknown
  show_in_menu: boolean
  country: {
    id: number
    name: string
    title: string
  }
  region: {
    id: number
    name: string
    info: string
    l_order: number
    country: {
      id: number
      name: string
      title: string
    }
  }
  zone: {
    id: number
    name: string
  }
  main_photo_author: string
  map_type_default: string
  rating: number
  has_active_action: false
  show_limited_accoms: true
  featured_qty: number
  is_closed: false
  accomodations_count: number
  nearby_radius: number
  parent: number
  is_hub: false
  map_url: string
  yandex_map_url: string
  is_resort_allows_filter: true
  show_on_show_ons: number[]
  parents: Array<{
    id: number
    name: string
    name_case: string
    title: unknown
    synonym: string
    url: string
    latitude: number
    longitude: number
  }>
}

export type Req = {
  country__in: number
  per_page: number
}

export type Resp = {
  results: Resort[]
}

export type ResortReq = {}
export type ResortResp = Resort