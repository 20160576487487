/* eslint-disable max-len */

export type GetPaymentsListRes = {
  count: number
  next: string | null
  previous: string | null
  results: {
    id: number
    accomodation: {
      id: number
      accomcategory: number
      accom_type: string
      name: string
      city: number
      address: string
      url: string | null
      status: number
      status_verbose: string
      service_end_date: string
      thumbnail_url: string
      thumbnail_small_url: string
      thumbnail_medium_url: string
      thumbnail_big_url: string
      is_in_active_region: boolean
    }
    service: {
      id: string | null
      service: {
        name: string
      }
      start_date: string | null
      end_date: string | null
      price_fixed: string
      discount_value: string | null
      discount_desc: string | null
      status: number
      status_verbose: number
    }
    bill: {
      id: string
      accom_service: number
      create_date: string
      amount: number
      paid_date: string | null
      paid_type: number
      paid_type_verbose: string
      status: number
      status_verbose: string
    } | null
    pay_form_state: string
  }[]
}


export type GetPaymentsListReq = {}
  
export const GET_PAYMENTS_LIST = '/v1/account/payments/'
export const GET_PAYMENT = '/v1/account/payments/:accommodationId/'
export const CREATE_PAYMENT = '/v1/account/payments/:accommodationId/create_bill/'
