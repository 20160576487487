import qs from 'querystring'

import { makeAutoObservable } from 'mobx'

import { THelpArticle } from 'src/network/api/helpArticles/get'
import { api } from 'src/network/http'
import * as Apitypes from 'src/network/api'
import { appHistory } from 'src/history'


type TOpenHintModalProps = {
  slug: string
}

class HintModalStore {
  isOpen = false
  isLoading = false

  slug = ''
  title = ''
  content = ''

  savedArticles: { [slug: string]: THelpArticle } = {}

  constructor() {
    makeAutoObservable(this)
  }

  openHintModal = async ({ slug }: TOpenHintModalProps) => {
    try {
      this._setArticleQuery(slug)

      if (this._getSavedArticle(slug)) {
        this._articleToModalState(
          this._getSavedArticle(slug),
        )

        return
      }

      this.isLoading = true
      this.isOpen = true

      const article = await api.get(
        Apitypes.HelpArticles.Get.GET_ARTICLE_BY_SLUG_URL,
        undefined,
        { replaceUrl: { slug } },
      )

      if (!article.visible) {
        this.closeHintModal()
        return
      }

      this._addSavedArticle(article)
      this._articleToModalState(article)
    } catch {
      // this.closeHintModal()
    } finally {
      this.isLoading = false
    }
  }

  closeHintModal = () => {
    this._articleToModalState(null)
    this._setArticleQuery(null)
  }

  _setArticleQuery = (slug: string | null) => {
    const { search, hash, pathname } = appHistory.location

    const query = qs.parse(search.slice(1, search.length))
    if (slug) {
      query.article = slug
    } else {
      delete query.article
    }

    appHistory.replace({
      search: (slug ? '?' : '') + qs.stringify(query),
      hash,
      pathname,
    })
  }

  _getSavedArticle = (slug: string) => {
    return this.savedArticles[slug]
  }

  _addSavedArticle = (article: THelpArticle) => {
    this.savedArticles = {
      ...this.savedArticles,
      [article.slug]: article,
    }
  }

  _articleToModalState = (article: THelpArticle | null) => {
    this.isOpen = !!article
    this.isLoading = false

    this.title = article?.html_title ?? ''
    this.content = article?.content ?? ''
    this.slug = article?.slug ?? ''
  }
}

export const hintModalStore = new HintModalStore()