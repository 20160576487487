import React from 'react'
import { GlobalStyles } from '@mui/material'

import { globalStyles } from 'src/styles/global'


export interface AppStylesProps {}

export const AppStyles: React.FC<AppStylesProps> = () => (
  <GlobalStyles styles={globalStyles as any} />
)