import { Category } from './categoriesList'


export const URL = '/v1/account/objects/:id/room_types/'

export type Req = {
  name: string
  balcon?: boolean
  conditioner?: 0 | 1
  description?: string
  extra_person_qty?: number
  fridge?: boolean
  kitchen?: boolean
  person_qty?: number
  room_qty?: number
  square?: number
  toilet?: undefined
  tv?: boolean
}

export type Resp = Category