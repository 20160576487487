export const URL = '/v1/auth/login/'

export type Req = {
  email: string
  password: string
}

export type Resp = {
  is_authenticated: boolean
}
