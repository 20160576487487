import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { styled, Tab, Tabs } from '@mui/material'
import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as LogoIcon } from 'src/icons/logo.svg'
import {
  CALENDAR_URL,
  ACCOMS_LIST_URL,
  PAYMENT_URL,
  RESERVATIONS_URL,
  REVIEWS_URL,
} from 'src/utils/urlUtils'
import { tabsStore } from 'src/stores/tabStore'
import { useIsMobile } from 'src/common/hooks/useIsMobile'
import { VARS } from 'src/styles/vars'
import { touristReqestsStore } from 'src/stores/touristReqests'

import { Drawer } from '../Drawer/Drawer'
import { ReservationsIndicator } from '../../ReservationsIndicator/ReservationsIndicator'


type Props = {
  className?: string
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: VARS.WHITE,
    height: '3px',
  },
  '&.MuiTabs-root': {
    height: '100%',
    borderBottom: 'none',
    marginLeft: '44px',
  },
  '& .MuiTab-root': {
    padding: 0,
    margin: '0 16px',
    minWidth: 'unset',
  },
  '& .MuiTabs-flexContainer': { height: '100%' },
})

const StyledTab = styled((props: any) => (
  <Tab
    disableRipple
    {...props}
  />
))(() => ({
  color: 'rgba(255, 255, 255, 0.7)',
  overflow: 'visible',
  textTransform: 'capitalize',
  fontSize: 16,
  fontWeight: 400,
  '&.Mui-selected': { color: VARS.WHITE },
  '&.Mui-focusVisible': { backgroundColor: 'rgba(100, 95, 228, 0.32)' },
}))


export const DesktopHeader: React.FC<Props> = observer(({ className }) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const [isVisible, setVisibility] = React.useState(false)
  const location = useLocation()
  const count = touristReqestsStore.countMap.unread

  const TABS = [
    {
      label: 'Главная',
      url: ACCOMS_LIST_URL,
    },
    {
      label: 'Шахматка',
      url: CALENDAR_URL,
    },
    {
      label:(
        <Box
          position='relative'
          paddingRight={count > 0 ? '16px' : 0}
          paddingTop='3px'
          marginTop='-3px'
        >
          Бронирования
          { ' ' }
          <ReservationsIndicator />
        </Box>
      ),
      url: RESERVATIONS_URL,
    },
    {
      label: 'Отзывы',
      url: REVIEWS_URL,
    },
    {
      label: 'Оплата',
      url: PAYMENT_URL,
    },
  ]

  useEffect(() => {
    const tabValue = `/${location.pathname.split('/')[1]}`
    const isRightRoute = TABS.find((t) => t.url.includes(tabValue))

    if (tabValue !== '/' && isRightRoute) {
      tabsStore.setTab(`/${location.pathname.split('/')[1]}`)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  if (isMobile) {
    return null
  }

  return (
    <Box
      className={className}
      sx={{ zIndex: 2 }}
    >
      <Drawer
        isVisible={isVisible}
        setVisibility={setVisibility}
      />
      <AppBar
        position='static'
        sx={{ height: '72px' }}
      >
        <Toolbar sx={{ height: '100%' }}>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={() => setVisibility(true)}
          >
            <MenuIcon />
          </IconButton>

          <Link
            to='/'
          >
            <LogoIcon />
          </Link>

          
          <AntTabs
            value={tabsStore.tab}
            onChange={
              (_, v) => {
                tabsStore.setTab(v)
              }
            }
          >
            {
              TABS.map(({ label, url }) => (
                <StyledTab
                  key={label}
                  label={
                    <Box
                      marginLeft='-4px'
                      marginRight='-4px'
                    >
                      { label }
                    </Box>
                  }
                  value={url}
                  onClick={() => navigate(url)}
                />
              ))
            }
          </AntTabs>
        </Toolbar>
      </AppBar>
    </Box>
  )
})
