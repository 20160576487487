import { Accomodation } from './get'


export const URL = '/v1/account/objects/:id/'

export type Req = {
  name?: string
  address?: string
  latitude?: number
  longitude?: number
}

export type Resp = Accomodation