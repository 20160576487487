import { makeAutoObservable } from 'mobx'


class ErrorModalStore {
  isOpen = false

  title = ''
  content = ''


  constructor() {
    makeAutoObservable(this)
  }

  openModal = (content: string) => {
    this.content = content
    this.isOpen = true
  }

  closeModal = () => {
    this.isOpen = false
  }
}

export const errorModalStore = new ErrorModalStore()