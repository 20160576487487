export type AddOwnerMessageReq = {
  message: string
}
  
export type AddOwnerMessageResp = AddOwnerMessageReq
    
export const ADD_OWNER_MESSAGE = 'v1/account/tourist_requests/:request_id/add_message/'

export type MarkReadReq = {}
    
export type MarkReadResp = {}
      
export const MARK_READ = 'v1/account/tourist_requests/:request_id/mark_read/'
