import React, { useEffect, useRef } from 'react'
import {
  Backdrop,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Box,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { SxProps } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

import { VARS } from 'src/styles/vars'
import { useIsMobile } from 'src/common/hooks/useIsMobile'


export interface CommonDialogProps {
  isOpen: boolean
  onClose: () => void
  onAdmit?: () => void
  title?: string
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>
  contentClassName?: string
  contentSx?: SxProps<Theme>
  cancelBtnProps?: Omit<ButtonProps, 'onClick'>
  admitBtnProps?: Omit<ButtonProps, 'onClick'>
  isLoading?: boolean
  isContentLoading?: boolean
  muiContentSx?: SxProps<Theme>
  customDialogActions?: React.ReactNode
  dialogActionsSx?: SxProps<Theme>
  disableMobileFullscreen?: boolean
  children: React.ReactNode
}

export const CommonDialog: React.FC<CommonDialogProps> = ({
  isOpen,
  title,
  dialogProps,
  onClose,
  onAdmit,
  cancelBtnProps,
  admitBtnProps,
  isLoading,
  children,
  isContentLoading,
  contentSx,
  muiContentSx,
  customDialogActions,
  dialogActionsSx,
  disableMobileFullscreen,
}) => {
  const isMobileScreen = useIsMobile()
  const isMobile = isMobileScreen && !disableMobileFullscreen

  const handleClose = () => {
    if (isLoading) {
      return
    }

    onClose()
  }

  const scrollY = useRef(0)

  const resetOverflow = () => {
    setTimeout(() => {
      document.body.style.overflowY = 'auto'
      document.body.style.height = 'initial'
      document.body.style.touchAction = 'auto'
      document.body.style.position = 'initial'
      document.body.ontouchmove = null
      document.body.onscroll = null
    }, 0)
  }

  const lockOverflow = () => {
    setTimeout(() => {
      scrollY.current = window.scrollY
      document.body.style.overflowY = 'hidden'
      if (isMobile) {
        document.body.style.position = 'fixed'
        document.body.style.height = `calc(${window.innerHeight}px - 1px)`
      }
      document.body.style.touchAction = 'none'
      document.body.ontouchmove = (e) => {
        e.preventDefault()
        return false
      }
      document.body.onscroll = (e) => {
        e.preventDefault()
        return false
      }
    }, 0)
  }

  useEffect(() => {
    if (isOpen) {
      lockOverflow()
    } else {
      resetOverflow()
    }
    return () => resetOverflow()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isMobile])

  // контент модалки мигает при закрытии без этой проверки
  if (!isOpen) {
    return null
  }

  let sx: SxProps<Theme> = {
    marginTop: isMobile ? 3 : 2,
    minWidth: isMobile ? 0 : 240,
    marginBottom: 2,
  }

  if (contentSx) {
    sx = {
      ...sx,
      ...contentSx,
    }
  }

  let muiSx: SxProps<Theme> = {
    paddingBottom: 0,
    paddingLeft: isMobile ? '16px' : '24px',
    paddingRight: isMobile ? '16px' : '24px',
    overflowX: 'hidden',
  }
  
  if (muiContentSx) {
    muiSx = {
      ...muiSx,
      ...muiContentSx,
    }
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      fullScreen={isMobile}
      {...dialogProps}
    >
      <DialogTitle
        sx={
          {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'center' : 'flex-start',
            paddingY: 0,
            minHeight: isMobile ? {
              xs: '56px',
              sm: '64px',
            } : '64px',
            paddingX: isMobile ? '16px' : undefined,
            color: isMobile ? 'white' : undefined,
            backgroundColor: isMobile ? VARS.BLUE_MAIN : undefined,
          }
        }
      >
        <Box
          sx={
            {
              order: isMobile ? 1 : 0,
              marginRight: isMobile ? 'auto' : 0,
              fontWeight: isMobile ? '500' : '400',
              fontSize: isMobile ? '20px' : '24px',
              lineHeight: isMobile ? '160%' : '132%',
              paddingTop: isMobile ? 0 : '15px',
              paddingBottom: isMobile ? 0 : '15px',
            }
          }
        >
          { title }
        </Box>
        <IconButton
          edge='start'
          size='large'
          color='inherit'
          onClick={handleClose}
          aria-label='close'
          sx={
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: isLoading ? 'auto' : 'pointer',
              color: !isMobile ? VARS.TEXT_SECONDARY : 'inherit',
              marginLeft: isMobile ? '-16px' : 2,
              marginRight: isMobile ? '16px' : '-16px',
              marginTop: isMobile ? 0 : '7px',
            }
          }
        >
          <CloseIcon />
        </IconButton>

        {
          isMobile && onAdmit && (
            <Button
              variant='contained'
              color='secondary'
              size='small'
              onClick={!isLoading ? onAdmit : undefined}
              {...admitBtnProps}
              sx={
                {
                  backgroundColor: '#fff',
                  marginLeft: 1,
                  order: 3,
                  ...admitBtnProps?.sx,
                }
              }
            >
              { admitBtnProps?.children ?? 'Сохранить' }
            </Button>
          )
        }
      </DialogTitle>

      <DialogContent
        sx={muiSx}
      >
        {
          isContentLoading ? (
            <Box
              sx={
                {
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '60px',
                }
              }
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={sx}
            >
              { children }
            </Box>
          )
        }

      </DialogContent>
      {
        !isMobile && (
          <DialogActions
            sx={
              {
                padding: '16px 24px',
                ...dialogActionsSx,
              }
            }
          >
            { customDialogActions }

            <Button
              variant='contained'
              color='secondary'
              onClick={handleClose}
              disabled={isLoading}
              {...cancelBtnProps}
            >
              { cancelBtnProps?.children ?? 'Закрыть' }
            </Button>

            {
              onAdmit && (
                <LoadingButton
                  variant='contained'
                  onClick={onAdmit}
                  loading={isLoading}
                  {...admitBtnProps}
                  sx={
                    {
                      marginLeft: 1,
                      ...admitBtnProps?.sx,
                    }
                  }
                >
                  { admitBtnProps?.children ?? 'Сохранить' }
                </LoadingButton>
              )
            }
          </DialogActions>
        )
      }

      {
        isLoading && (
          <Backdrop
            sx={
              {
                color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,
              }
            }
            open={isLoading}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        )
      }
    </Dialog>

  )
}
