import React, { Suspense } from 'react'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import CircularProgress from '@mui/material/CircularProgress'

import { DesktopHeader } from 'src/common/components/Header/DesktopHeader/DesktopHeader'
import { AppRouter } from 'src/common/components/_global/AppRouter/AppRouter'
import { Footer } from 'src/common/components/Footer/Footer'
import { appHistory } from 'src/history'
import { appStore } from 'src/stores/appStore'
import { authStore } from 'src/stores/authStore'

import {
  CALENDAR_URL,
  ERROR_URL,
  LOGIN_URL,
  NOT_FOUND_ERROR_URL,
  PAYMENT_URL,
  PERMISSION_ERROR_URL,
  ACCOMS_EDIT_URL,
  ACCOMS_LIST_URL,
  ACCOM_CALENDAR_URL,
  ACCOM_CALENDAR_WITH_DATE_URL,
  ACCOM_PAYMENT_URL,
  ACCOM_REVIEWS_URL,
  PROFILE_URL,
  RESERVATIONS_THREAD_URL,
  RESERVATIONS_URL,
  RESTORE_PASSWORD_URL,
  REVIEWS_URL,
  SIGN_UP_URL,
  MORE_URL,
} from '../utils/urlUtils'


const ReservationsThread = React.lazy(() => import('./ReservationsThread/ReservationsThreadLazy'))
const PlaceReviews = React.lazy(() => import('./Reviews/PlaceReviewsLazy'))
const PlacePayment = React.lazy(() => import('./Payment/PlacePaymentLazy'))
const Profile = React.lazy(() => import('./Profile/ProfileLazy'))
const Reservations = React.lazy(() => import('./Reservations/ReservationsLazy'))
const Reviews = React.lazy(() => import('./Reviews/ReviewsLazy'))
const EditAccomodation = React.lazy(() => import('./Accomodations/EditAccomodation/EditAccomodationLazy'))
const Payment = React.lazy(() => import('./Payment/PaymentLazy'))
const AccomodationsList = React.lazy(() => import('./Accomodations/AccomodationsList/AccomodationsListLazy'))
const AddPlace = React.lazy(() => import('./Accomodations/AddAccomodation/AddAccomodationLazy'))
const Login = React.lazy(() => import('./Auth/Login/LoginLazy'))
const RestorePassword = React.lazy(() => import('./Auth/RestorePassword/RestorePasswordLazy'))
const SignUp = React.lazy(() => import('./Auth/SignUp/SignUpLazy'))
const ErrorPage = React.lazy(() => import('./ErrorPage/ErrorPageLazy'))
const CalendarPage = React.lazy(() => import('./Calendar/CalendarPageLazy'))
const PlaceCalendarPage = React.lazy(() => import('./Calendar/PlaceCalendarPageLazy'))
const More = React.lazy(() => import('./More/MoreLazy'))

type Props = {
  children: React.ReactNode
}

export const AppRoutes: React.FC<Props> = observer(({ children }) => {
  if (!appStore.isAppLoaded) {
    return null
  }

  const errorRoutes = () => {
    return (
      <>
        <Route
          path={ERROR_URL}
          element={<ErrorPage error={500} />}
        />
        <Route
          path={PERMISSION_ERROR_URL}
          element={<ErrorPage error={403} />}
        />
        <Route
          path={NOT_FOUND_ERROR_URL}
          element={<ErrorPage error={404} />}
        />
      </>
    )
  }

  if (!authStore.isUserLoggedIn) {
    return (
      <Suspense fallback={<CircularProgress sx={{ margin: '100px auto' }} />}>
        <AppRouter history={appHistory}>
          <Routes>
            <Route
              path={LOGIN_URL}
              element={<Login />}
            />
            <Route
              path={RESTORE_PASSWORD_URL}
              element={<RestorePassword />}
            />
            <Route
              path={SIGN_UP_URL}
              element={<SignUp />}
            />

            { errorRoutes() }

            <Route
              path='*'
              element={
                <Navigate
                  replace
                  to={LOGIN_URL}
                />
              }
            />
          
          </Routes>

          { children }
        
        </AppRouter>
      </Suspense>
    )
  }


  if (appHistory.location.pathname.includes(LOGIN_URL)
    || appHistory.location.pathname.includes(SIGN_UP_URL)
    || appHistory.location.pathname.includes(RESTORE_PASSWORD_URL)
  ) {
    appHistory.replace('/')
  }

  return (
    <Suspense fallback={<CircularProgress sx={{ margin: '100px auto' }} />}>
      <AppRouter history={appHistory}>
        <DesktopHeader />

        <Routes>
          <Route
            path={ACCOMS_LIST_URL}
            element={<AccomodationsList />}
          />
          <Route
            path={ACCOMS_EDIT_URL}
            element={<EditAccomodation />}
          />
          <Route
            path='/objects/add/*'
            element={<AddPlace />}
          />
          <Route
            path={CALENDAR_URL}
            element={<CalendarPage />}
          />
          <Route
            path={ACCOM_CALENDAR_URL}
            element={<PlaceCalendarPage />}
          />
          <Route
            path={ACCOM_CALENDAR_WITH_DATE_URL}
            element={<PlaceCalendarPage />}
          />
          <Route
            path={RESERVATIONS_URL}
            element={<Reservations />}
          />
          <Route
            path={RESERVATIONS_THREAD_URL}
            element={<ReservationsThread />}
          />
          <Route
            path={REVIEWS_URL}
            element={<Reviews />}
          />
          <Route
            path={ACCOM_REVIEWS_URL}
            element={<PlaceReviews />}
          />
          <Route
            path={PAYMENT_URL}
            element={<Payment />}
          />
          <Route
            path={ACCOM_PAYMENT_URL}
            element={<PlacePayment />}
          />
          <Route
            path={PROFILE_URL}
            element={<Profile />}
          />
          <Route
            path={MORE_URL}
            element={<More />}
          />
          <Route
            path='/'
            element={
              <Navigate
                replace
                to={ACCOMS_LIST_URL}
              />
            }
          />

          { errorRoutes() }

          <Route
            path='*'
            element={
              <Navigate
                replace
                to={NOT_FOUND_ERROR_URL}
              />
            }
          />
        </Routes>
      
        { children }
      
        <Footer />
      </AppRouter>
    </Suspense>
  )
})
