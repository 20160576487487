import { makeAutoObservable } from 'mobx'

import { ACCOMS_LIST_URL } from 'src/utils/urlUtils'


class TabsStore {
  tab: string = ACCOMS_LIST_URL

  constructor() {
    makeAutoObservable(this)
  }

  setTab = (url: string) => {
    this.tab = url
  }
}

export const tabsStore = new TabsStore()