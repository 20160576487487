import { Room } from './getRooms'


export const URL = '/v1/account/objects/:placeId/rooms/:roomId/'

export type Req = {
  number?: number
  floor?: number
  notes?: string
  room_type?: number
}

export type Resp = Room