import { Accomodation } from './get'


export const URL = '/v1/account/objects/'

export type Req = {
  accom_type: string
  name: string
  city: number
  address: string
  latitude: number
  longitude: number
}

export type Resp = Accomodation
