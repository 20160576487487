export type TouristRequest = {
  id: number
  uuid: string
  accomodation: number
  accomodation_name: string
  room: number
  room_type: number
  room_name: string
  room_type_name: string
  checkin_date: string
  checkout_date: string
  phone: string | null
  email: string
  username: string
  contact_person: string
  adults: number
  children: number
  children_ages: string
  is_thread_hidden: boolean
  message_total_count: number
  message_unread_count: number
  create_date: string
}
  

export type GetRequestsResp = {
  count: number
  next: null | string
  previous: null | string
  total_pages: number
  results: TouristRequest[]
}

export type GetRequestsReq = {
  page: number
  per_page: number
  hidden?: boolean
  checkout_date__gt?: string
  has_unread_messages?: boolean
}
  
export const GET_TOURIST_REQUESTS = 'v1/account/tourist_requests/'

export const GET_TOURIST_REQUESTS_COUNT = 'v1/account/tourist_requests/statistic'

export type GetRequestsCountResp = {
  all: number
  actual: number
  unread: number
}


export type Message = {
  id: number
  message: string
  create_date: string
  send_date: string
  is_readed: boolean
  is_from_tourist: boolean
}

export type GetRequestResp = TouristRequest & {
  messages: Message[]
}
  
export const GET_TOURIST_REQUEST = 'v1/account/tourist_requests/:request_id/'
