import React from 'react'
import { Box, Divider, Drawer as MuiDrawer, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import EventRoundedIcon from '@mui/icons-material/EventRounded'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { authStore } from 'src/stores/authStore'
import { useIsMobile } from 'src/common/hooks/useIsMobile'
import { ReactComponent as LogoIcon } from 'src/icons/logo.svg'
import { VARS } from 'src/styles/vars'
import { animate } from 'src/styles/mixins'
import {
  CALENDAR_URL,
  LOGIN_URL,
  PAYMENT_URL,
  ACCOMS_LIST_URL,
  PROFILE_URL,
  RESERVATIONS_URL,
  REVIEWS_URL,
  SIGN_UP_URL,
} from 'src/utils/urlUtils'

import { ReservationsIndicator } from '../../ReservationsIndicator/ReservationsIndicator'

import { ReactComponent as LoginIcon } from './login.svg'


type OwnProps = {
  isVisible: boolean
  setVisibility: (value: boolean) => void
}

const ITEMS = [
  {
    label: 'Главная',
    icon: <HomeOutlinedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: ACCOMS_LIST_URL,
  },
  {
    label: 'Шахматка',
    icon: <EventRoundedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: CALENDAR_URL,
  },
  {
    label:
  <Box
    position='relative'
    paddingRight='17px'
  >
    Бронирования
    <ReservationsIndicator disableInterval />
  </Box>,
    icon: <AssignmentTurnedInOutlinedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: RESERVATIONS_URL,
  },
  {
    label: 'Отзывы',
    icon: <RateReviewOutlinedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: REVIEWS_URL,
  },
  {
    label: 'Оплата',
    icon: <PaymentOutlinedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: PAYMENT_URL,
  },
]

const ITEMS_2 = [
  {
    label: 'Настройки профиля',
    icon: <SettingsOutlinedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: PROFILE_URL,
  },
  {
    label: 'На kudanamore.ru',
    icon: <OpenInNewOutlinedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: 'https://www.kudanamore.ru',
  },
  {
    label: 'Выход',
    icon: <ExitToAppIcon />,
    link: '',
  },
]

const ITEMS_3 = [
  {
    label: 'Вход',
    icon: <LoginIcon />,
    link: LOGIN_URL,
  },
  {
    label: 'Регистрация',
    icon: <PersonOutlineRoundedIcon sx={{ color: VARS.ACTION_ACTIVE }} />,
    link: SIGN_UP_URL,
  },
]

export const Drawer: React.FC<OwnProps> = ({ isVisible, setVisibility }) => {
  const isMobile = useIsMobile()

  const renderLink = (item: typeof ITEMS[number], index: number, onClick?: () => void) => {
    return (
      <div
        key={index}
        onClick={() => setVisibility(false)}
      >
        <Typography
          variant='body1'
          component={onClick ? 'div' : Link}
          color='text.primary'
          to={onClick ? undefined : item.link}
          onClick={onClick}
          sx={
            {
              display: 'flex',
              alignItems: 'center',
              height: '48px',
              cursor: 'pointer',
              padding: '0 16px',
              ...animate('background'),
              '&:hover': { background: VARS.ACTION_HOVER },
            }
          }
        >
          { item.icon }
          <Box
            component='span'
            sx={{ marginLeft: '32px' }}
          >
            { item.label }
          </Box>
        </Typography>
      </div>
    )
  }

  const renderAuthenticatedContent = () => {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={
            {
              margin: '16px',
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              background:  authStore.user?.avatar ? `url('${authStore.user?.avatar}')` : VARS.PRIMARY_LIGHT,
              backgroundSize: 'cover',
              color: VARS.WHITE,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
            }
          }
          >
            {
              authStore.user?.avatar ? '' :
                authStore.user?.first_name?.length && authStore.user?.first_name[0]
            }
          </Box>
          <Box sx={{ padding: '12px 0' }}>
            <Typography
              variant='body1'
              component='div'
              color='text.primary'
            >
              { authStore.user?.first_name }
            </Typography>
            <Typography
              variant='body1'
              component='div'
              color='text.primary'
            >
              { authStore.user?.last_name }
            </Typography>
          </Box>
        </Box>
        {
          ITEMS.map((item, index) => renderLink(item, index))
        }
        <Divider />
        {
          renderLink(ITEMS_2[0], 1, () => {
            window.open(ITEMS_2[0].link, '_blank')
          })
        }
        {
          renderLink(ITEMS_2[1], 2, () => {
            window.open(ITEMS_2[1].link, '_blank')
          })
        }
        {
          renderLink(ITEMS_2[2], 3, () => {
            authStore.logout()
          })
        }

      </>
    )
  }

  const renderLoginContent = () => {
    return ITEMS_3.map((item, index) => renderLink(item, index))
  }

  return (
    <MuiDrawer
      anchor='left'
      open={isVisible}
      onClose={() => setVisibility(false)}
    >
      <Box sx={{ width: isMobile ? '292px' : '332px' }}>
        <Box
          sx={
            {
              height: {
                xs: '56px',
                sm: '64px',
                lg: '72px',
              },
              paddingLeft: '16px',
              display: 'flex',
              alignItems: 'center',
              background: VARS.BLUE_MAIN,
            }
          }
        >
          <LogoIcon />
        </Box>
        { authStore.isUserLoggedIn ? renderAuthenticatedContent() : renderLoginContent() }
      </Box>
    </MuiDrawer>
  )
}
