/* eslint-disable max-len */

export type GetReviewsRes = {
  create_date: string
  modify_date: string
  comment: string
}
      
  
export type PatchReplyReq = { comment: string }
  
export const PATCH_REPLY = 'v1/account/objects/:accommodationId/reviews/:reviewId/reply/'
