/* eslint-disable max-len */
export type WorkingPeriod = {
  id: number
  year: number
  start_date: string
  end_date: string
}

export type WorkingPeriodsReq = {
  year: number
  per_page: number
}

export type WorkingPeriodsRes = {
  count: number
  next: null | string
  previous: null | string
  total_pages: number
  results: WorkingPeriod[]
}

export const GET_WORKING_PERIODS_URL = '/v1/account/objects/:accomodation_id/working_periods/'

export type InitWorkingPeriodReq = {
  year: number
  start_date: string
  end_date: string
}

export type CopyWorkingPeriodFromYearReq = {
  year: number
}

export type InitWorkingPeriodRes = WorkingPeriod[]

export const INIT_WORKING_PERIODS_URL = '/v1/account/objects/:accomodation_id/working_periods/init/'

export type DeleteWorkingPeriodRes = WorkingPeriod[]

export const DELETE_WORKING_PERIOD_URL = '/v1/account/objects/:accomodation_id/working_periods/:period_id/'

export type UpdateWorkingPeriodReq = {
  start_date: string
  end_date: string
}

export type UpdateWorkingPeriodRes = WorkingPeriod[]

export const UPDATE_WORKING_PERIOD_URL = '/v1/account/objects/:accomodation_id/working_periods/:period_id/'

export type AddWorkingPeriodReq = {
  border_date: string
  position: 'left' | 'right'
}

export type AddWorkingPeriodRes = WorkingPeriod[]

export const ADD_WORKING_PERIODS_URL = '/v1/account/objects/:accomodation_id/working_periods/:period_id/new_neighbor/'

export type SplitWorkingPeriodReq = {
  border_date: string
}

export type SplitWorkingPeriodRes = WorkingPeriod[]

export const SPLIT_WORKING_PERIODS_URL = '/v1/account/objects/:accomodation_id/working_periods/:period_id/split/'

export type GetWorkingPeriodPricesReq = {
  year: number
}

export type GetWorkingPeriodPricesRes = {
  meal: 1 | 2 | 3 | 4 | 5 | 6
  cost_type: 1 | 2
  room_prices: {
    id: number
    type: number
    period: number
    price: number
  }[]
  room_extra_prices: {
    id: number
    type: number
    period: number
    price: number
  }[]
}
export const GET_WORKING_PERIODS_PRICES_URL = '/v1/account/objects/:accomodation_id/room_types/:accomodation_type/prices/'

export type UpdateWorkingPeriodPricesReq = {
  meal: 1 | 2 | 3 | 4 | 5 | 6
  cost_type: 1 | 2
  room_prices: {
    id: number
    price: number
  }[]
  room_extra_prices?: {
    id: number
    price: number
  }[]
}

export type UpdateWorkingPeriodPricesRes = {
  meal: 1 | 2 | 3 | 4 | 5 | 6
  cost_type: 1 | 2
  room_prices: {
    id: number
    type: number
    period: number
    price: number
  }[]
  room_extra_prices: {
    id: number
    type: number
    period: number
    price: number
  }[]
}
export const UPDATE_WORKING_PERIODS_PRICES_URL = '/v1/account/objects/:accomodation_id/room_types/:accomodation_type/prices/:year'