import { ButtonProps, SnackbarProps } from '@mui/material'
import { makeAutoObservable } from 'mobx'


interface ShowSnackbarProps {
  snackbarProps: Omit<SnackbarProps, 'open' | 'action'>
  snackbarButtonProps?: ButtonProps
}

// в mui можно показывать только один снекбар одновременно
class SnackbarStore {
  snackbar: SnackbarProps | null = null
  snackbarButtonProps: ButtonProps | null | undefined = null

  constructor() {
    makeAutoObservable(this)
  }

  closeSnackbar = () => {
    this.snackbar = null
    this.snackbarButtonProps = null
  }

  showSnackbar = ({
    snackbarProps,
    snackbarButtonProps,
  }: ShowSnackbarProps) => {
    this.snackbar = { ...snackbarProps }
    this.snackbarButtonProps = snackbarButtonProps
  }

  updateSnackbar = ({
    snackbarProps,
    snackbarButtonProps,
  }: Partial<ShowSnackbarProps>) => {
    if (snackbarProps) {
      this.snackbar = snackbarProps
    }

    if (this.snackbarButtonProps) {
      this.snackbarButtonProps = snackbarButtonProps
    }
  }
}

export const snackbarStore = new SnackbarStore()