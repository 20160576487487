export const URL = '/v1/account/objects/:id/media/'

export type Req = void

export type ImagesFormats = {
  XL: string
  XL_webp: string
  XXL: string
  XXL_webp: string
}

export type PlaceMedia = {
  id: number
  type: number
  img: string
  description: string
  primary: boolean
  angle: number
  order: number
  room_type: number
  video_url: string
  thumbnail_small_url: string
  thumbnail_medium_url: string
  thumbnail_big_url: string
  youtube_screenshot_url: string
  youtube_player_url: string
  images: ImagesFormats
}

export type Resp = {
  count: number
  next: string | null
  previous: string | null
  results: PlaceMedia[]
  total_pages: number
}