import { makeAutoObservable } from 'mobx'


class BackdropStore {
  isOpen: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsOpen = (value: boolean) => {
    this.isOpen = value
  }
}

export const backdropStore = new BackdropStore()