import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'

import { api } from 'src/network/http'
import * as Apitypes from 'src/network/api'
import { apiErrorsToClient } from 'src/utils/tsUtils'
import { appHistory } from 'src/history'
import { ACCOMS_LIST_URL } from 'src/utils/urlUtils'


class AuthStore {
  isUserLoggedIn = false
  user: Apitypes.Auth.GetUserInfo.Resp | null = null
  setPswdErrors: string[] = []
  loginErrors: string[] = []
  signUpErrors: string[] = []
  restorePasswordErrors: string[] = []

  isLoginLoading = false
  isSignUpLoading = false
  restorePasswordLoading = false
  setPasswordLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  clearSetPswdErrors = () => {
    this.setPswdErrors = []
  }

  clearLoginErrors = () => {
    this.loginErrors = []
  }

  clearSignUpErrors = () => {
    this.signUpErrors = []
  }

  clearRestorePasswordErrors = () => {
    this.restorePasswordErrors = []
  }

  checkIsUserLoggedIn = async () => {
    const resp = await api.get(Apitypes.Auth.GetAuth.URL, undefined, {})

    if (resp.is_authenticated) {
      await this.getUserInfo()
    }

    this.isUserLoggedIn = resp.is_authenticated
  }

  signUp = async (data: Apitypes.Auth.SignUp.Req) => {
    this.isSignUpLoading = true
    try {
      const resp = await api.post(Apitypes.Auth.SignUp.URL, data)
  
      if (resp.success) {
        this.isUserLoggedIn = true

        this.isSignUpLoading = false

        appHistory.push(ACCOMS_LIST_URL)
        return
      }
  
      throw new Error()
    } catch (error) {
      // reset captcha
      (window as any).grecaptcha.reset()
  
      const aError = error as AxiosError
  
      this.signUpErrors = apiErrorsToClient(
        aError.response?.data,
        'Не удалось отправить форму, попробуйте позже',
        'Вы уже зарегистрированы',
      )
      this.isSignUpLoading = false
    }
  }

  login = async (data: Apitypes.Auth.Login.Req) => {
    this.isLoginLoading = true
    
    try {
      const { is_authenticated } = await api.post(Apitypes.Auth.Login.URL, data)

      this.isLoginLoading = false
      this.isUserLoggedIn = is_authenticated

      if (!is_authenticated) {
        throw new Error()
      }

      appHistory.push(ACCOMS_LIST_URL)
      await this.getUserInfo()
    } catch (error) {
      const aError = error as AxiosError

      this.loginErrors = apiErrorsToClient(
        aError.response?.data,
        'Не удалось отправить форму, попробуйте позже',
        'Вы уже вошли',
      )

      this.isLoginLoading = false
    }
  }

  restorePassword = async (data: Apitypes.Auth.RestorePassword.Req) => {
    this.restorePasswordLoading = true
    
    try {
      const resp = await api.post(Apitypes.Auth.RestorePassword.URL, data)

      if (resp.success) {
        this.restorePasswordLoading = false

        return true
      }


      if (!resp.success) {
        throw new Error()
      }
    } catch (error) {
      const aError = error as AxiosError

      this.restorePasswordErrors = apiErrorsToClient(
        aError.response?.data,
        'Не удалось отправить форму, попробуйте позже',
        'Не удалось отправить форму, попробуйте позже',
      )

      this.restorePasswordLoading = false
    }
  }

  setPassword = async (data: Apitypes.Auth.SetPassword.Req) => {
    this.setPasswordLoading = true

    try {
      const resp = await api.post(Apitypes.Auth.SetPassword.URL, data)
  
      if (resp.success) {
        this.setPasswordLoading = false
        return true
      }
  
      throw new Error()
    } catch (error) {
      const aError = error as AxiosError
  
      this.setPswdErrors = apiErrorsToClient(
        aError.response?.data,
        'Произошла непредвиденная ошибка, повторите позже',
        'Время восстановления пароля истекло, попробуйте снова',
      )

      this.setPasswordLoading = false
    }
  }

  getUserInfo = async () => {
    const resp = await api.get(Apitypes.Auth.GetUserInfo.URL)

    this.user = resp
  }

  logout = async () => {
    await api.post(Apitypes.Auth.Logout.URL, {})

    document.location.reload()
  }
}

export const authStore = new AuthStore()