import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { touristReqestsStore } from 'src/stores/touristReqests'
import { VARS } from 'src/styles/vars'


const useStyles = makeStyles(() => ({
  counter: {
    background: VARS.ERROR_DARK,
    color: VARS.WHITE,
    fontSize: 9,
    padding: '1px 4px 1px 4px',
    borderRadius: 64,
    position: 'absolute',
    top: 0,
    right: 3,
    transform: 'translateX(50%)',
    minWidth: 15,
  },
}), { name: 'ReservationsIndicator' })

type Props = {
  disableInterval?: boolean
}

export const ReservationsIndicator: React.FC<Props> = observer(({ disableInterval }) => {
  const classes = useStyles()

  useEffect(() => {
    if (!disableInterval) {
      touristReqestsStore.getTouristRequestsCount('unread')

      const interval = setInterval(() => {
        touristReqestsStore.getTouristRequestsCount('unread')
      }, 10 * 1000)
  
      return () => clearInterval(interval)
    }
  }, [disableInterval])
  const count = touristReqestsStore.countMap.unread

  if (count === 0) {
    return null
  }

  return <div className={classes.counter}>{ count > 99 ? '99+' : count }</div>
})