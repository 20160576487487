/* eslint-disable max-len */
import { PartialBy } from 'src/utils/typeUtils'

import { TPlacePhone } from './get'


export const ADD_PHONE_URL = 'v1/account/objects/:id/phones/'
export type AddPhoneReq = PartialBy<TPlacePhone, 'comment' | 'has_viber' | 'has_whatsapp' | 'has_telegram' | 'l_order' | 'id'>
export type AddPhoneResp = Omit<TPlacePhone, 'id'> & { pk: number }

export const ADD_SMS_NOTIFY_PHONE_URL = '/v1/users/profile/'
export type AddSmsNotifyPhoneReq = { data: { sms_phone: string | null } }
