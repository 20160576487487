import { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios'

import { appHistory } from 'src/history'
import { ERROR_URL, GET_CSRF_TOKEN, NOT_FOUND_ERROR_URL, PERMISSION_ERROR_URL } from 'src/utils/urlUtils'
import { isCsrfError } from 'src/utils/common'

import { setTimeoutPromise } from '../utils/tsUtils'

import { ReplaceUrl } from './http'


export type AppAxiosRequestConfig = AxiosRequestConfig & {
  replaceUrl?: ReplaceUrl
  needFullAxiosResp?: boolean
  retryCount?: number
}

export class ApiClass {
  headers: Record<string, string> = {}

  // eslint-disable-next-line no-useless-constructor
  constructor(private axios: AxiosInstance) { }

  async request(options: AppAxiosRequestConfig, requestCounter = 1): Promise<any> {
    let url = options?.url
    const { replaceUrl, needFullAxiosResp } = options

    if (url && replaceUrl) {
      const splittedUrl = url.split('/')
      url = splittedUrl
        .map((part) => {
          if (part.startsWith(':')) {
            const key = part.slice(1)

            return replaceUrl[key] ? String(replaceUrl[key]) : part
          }

          return part
        })
        .join('/')
    }

    return this.axios
      .request({
        ...options,
        url,
        headers: {
          ...this.headers,
          ...options.headers,
        },
      })
      .then((resp) => {
        if (needFullAxiosResp) {
          return resp
        }

        return resp.data
      })
      .catch((error: Error | AxiosError) => {
        const status = (error as AxiosError).response?.status || 0
        if (status >= 500 && requestCounter > 0) {
          return setTimeoutPromise(1000)
            .then(() => this.request(options, requestCounter - 1))
        }

        if (status === 500) {
          appHistory.replace(ERROR_URL)
        }

        if (
          (status === 403 && isCsrfError((error as AxiosError).response?.data)) && requestCounter > 0
        ) {
          document.cookie = 'csrftoken=; path=/; max-age=0;'
          document.cookie = 'csrftoken=; path=/; max-age=0; domain=.kudanamore.ru'
          return this.post(GET_CSRF_TOKEN, {})
            .then((res) => {
              if (res.csrf_cookie_name && res.csrf_cookie_name !== 'csrftoken') {
                return this.request({ ...options, xsrfCookieName: res.csrf_cookie_name }, requestCounter - 1)
              }
              return this.request(options, requestCounter - 1)
            })
            .catch(() => this.request(options, requestCounter - 1))
        }

        if (status === 400 && (error as AxiosError).response?.data?.errors?.[0]?.detail === 'Already authenticated') {
          setTimeout(() => {
            appHistory.replace('/objects')
            window.location.reload()
          }, 100)
        }

        if (status === 403) {
          appHistory.replace(PERMISSION_ERROR_URL)
        }

        if (status === 404) {
          appHistory.replace(NOT_FOUND_ERROR_URL)
        }

        return Promise.reject(error)
      })
  }

  get(url: string, params?: any, options?: AppAxiosRequestConfig) {
    return this.request({
      ...options,
      method: 'GET',
      params,
      url,
    })
  }

  post(url: string, data: any, options?: AppAxiosRequestConfig) {
    return this.request({
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
      ...options,
      method: 'POST',
      data,
      url,
    })
  }

  put(url: string, data: any, options?: AppAxiosRequestConfig) {
    return this.request({
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
      ...options,
      method: 'PUT',
      data,
      url,
    })
  }

  patch(url: string, data: any, options?: AppAxiosRequestConfig) {
    return this.request({
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
      ...options,
      method: 'PATCH',
      data,
      url,
    })
  }

  delete(url: string, options?: AppAxiosRequestConfig, data?: any) {
    return this.request({
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
      ...options,
      method: 'DELETE',
      data,
      url,
    })
  }

  options(url: string, options?: AppAxiosRequestConfig) {
    return this.request({
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
      ...options,
      method: 'OPTIONS',
      url,
    })
  }
}
