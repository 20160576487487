import React from 'react'
import { observer } from 'mobx-react-lite'
import { Backdrop as MUIBackdrop, CircularProgress } from '@mui/material'

import { backdropStore } from 'src/stores/backdropStore'
import { VARS } from 'src/styles/vars'


export const Backdrop: React.FC = observer(() => {
  const { isOpen } = backdropStore
  
  return (
    <MUIBackdrop
      sx={
        {
          color: VARS.WHITE,
          zIndex: (theme) => theme.zIndex.tooltip + 1,
        }
      }
      open={isOpen}
    >
      <CircularProgress color='inherit' />
    </MUIBackdrop>
  )
})