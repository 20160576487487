export const URL = '/v1/auth/set-password/'

export type Req = {
  new_password1: string
  new_password2: string
  token: string
}

export type Resp = {
  success: boolean
}
