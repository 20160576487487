import { createTheme } from '@mui/material/styles'

import { BREAKPOINTS } from 'src/styles/breakpoints'
import { VARS } from 'src/styles/vars'


export const THEME = createTheme({
  palette: {
    primary: { main: VARS.BLUE_MAIN },
    secondary: { main: VARS.SECONDARY },
    success: { main: VARS.SUCCESS },
  },

  typography: {
    h6_130: {
      margin: 0,
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: 1.3,
      letterSpacing: '0.0075em',
    },
  },

  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS}`,
        },
      },
    },

    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS}`,

        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS}`,
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          [theme.breakpoints.up('lg')]: {
            borderRadius:  `${VARS.BORDER_RADIUS_CONTROLS}`,
          },
        }),
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS} !important`,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS}`,

        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS} !important`,
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS}`,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: `${VARS.BORDER_RADIUS_CONTROLS}`,
          background: VARS.WHITE,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          borderRadius: '100px 100px 0px 0px',
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: `${VARS.PRIMARY} !important`,
          borderColor: `${VARS.GRAY_600} !important`,
          borderRadius: `${VARS.BUTTON_RADIUS}`,
          transition: '300ms background',
          whiteSpace: 'nowrap',
          textTransform: 'none',
          gap: '8px',
          lineHeight: 1.5,
          fontWeight: 500,
          fontSize: 14,
          padding: '5px 10px',

          '&.Mui-selected': {
            borderColor: `${VARS.GRAY_600} !important`,
            backgroundColor: `${VARS.BLUE_100} !important`,
          },
          
          '&:hover': {
            borderColor: `${VARS.GRAY_600} !important`,
            backgroundColor: `${VARS.SECONDARY_HOVER} !important`,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: `${VARS.BUTTON_RADIUS}`,
          textTransform: 'none',
          boxShadow: 'none !important',
        },
        containedSizeSmall: {
          fontSize: 14,
          lineHeight: '20px',
          padding: '7px 19px',
        },
        outlinedSizeSmall: {
          fontSize: 14,
          lineHeight: '20px',
          padding: '7px 19px',
        },
        containedSizeMedium: {
          fontSize: 16,
          lineHeight: '24px',
          padding: '7px 23px',
        },
        outlinedSizeMedium: {
          fontSize: 16,
          lineHeight: '24px',
          padding: '7px 23px',
        },
        containedSizeLarge: {
          fontSize: 18,
          lineHeight: '24px',
          padding: '7px 23px',
        },
        outlinedSizeLarge: {
          fontSize: 18,
          lineHeight: '24px',
          padding: '7px 23px',
        },
        contained: {
          border: '1px solid transparent',
        },
        containedSecondary: {
          color: VARS.BLUE_MAIN,
          backgroundColor: VARS.BLUE_100,
      
          '&:hover': {
            backgroundColor: VARS.SECONDARY_HOVER,
          },
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: BREAKPOINTS.XS,
      sm: BREAKPOINTS.SM,
      md: BREAKPOINTS.MD,
      lg: BREAKPOINTS.LG,
      xl: BREAKPOINTS.XL,
    },
  },
})
